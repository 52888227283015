<template>
  <div class="page page-game-detail">
    <b-col class="py-3">
      <LoadingPanel v-if="loading"/>
      <ErrorPanel v-else-if="error" :message="errorMessage" @retry="loadGame()"/>
      <NotFoundPanel v-else-if="errorMessage" :message="errorMessage"/>
      <div v-else>
        <Breadcrumb :items="[{label: $t('Game detail')}]"/>
        <div class="game-header pt-3 mb-4 d-flex">
          <img :src="getWebFileUrl(game.iconUrl)" :alt="game.name" class="image-icon mr-3"/>
          <div class="game-content w-100">
            <h1>{{ game.name }}</h1>
            <div class="game-category">{{ game.category }}</div>
<!--            <div class="play-game" v-if="game.gameUrl && game.gameUrl.length">-->
<!--              <a :href="game.gameUrl" class="btn-rounded font-weight-bold btn btn-lg btn-primary">{{ $t("Play now") }}</a>-->
<!--            </div>-->
<!--            <div class="play-game" v-else-if="game.downloadUrl && game.downloadUrl.length">-->
<!--              <a :href="game.downloadUrl" class="btn btn-primary btn-lg btn-rounded font-weight-bold">{{ $t("GameType" + game.downloadType) }}</a>-->
<!--            </div>-->

            <div class="play-game d-flex" style="gap: 10px">
              <a :href="game.downloadAndroidUrl" v-if="game.downloadAndroidUrl && game.downloadAndroidUrl.length" class="btn btn-primary btn-rounded font-weight-bold">ADR-PC</a>
              <a :href="game.downloadIosUrl" v-if="game.downloadIosUrl && game.downloadIosUrl.length" class="btn btn-primary btn-rounded font-weight-bold">IOS FREE</a>
            </div>
          </div>
        </div>
        <div class="game-body">
          <div class="game-toolbar mb-3 d-flex justify-content-between">
            <div class="item">
              <a :href="game.giftcodeUrl" v-if="game.giftcodeUrl && game.giftcodeUrl.length">
                <img src="../../assets/images/icon/game-toolbar/gc.svg" :alt="$t('Giftcode')">
                <label>{{ $t("Giftcode") }}</label>
              </a>
            </div>
            <div class="item">
              <a :href="game.chargeUrl" v-if="game.chargeUrl && game.chargeUrl.length">
                <img src="../../assets/images/icon/game-toolbar/fc.svg" :alt="$t('Charge card')">
                <label>{{ $t("Charge card") }}</label>
              </a>
            </div>
            <div class="item">
              <a :href="game.homeUrl" v-if="game.homeUrl && game.homeUrl.length">
                <img src="../../assets/images/icon/game-toolbar/home.svg" :alt="$t('Home page')">
                <label>{{ $t("Home page") }}</label>
              </a>
            </div>
            <div class="item">
              <a :href="game.fanpageUrl" v-if="game.fanpageUrl && game.fanpageUrl.length">
                <img src="../../assets/images/icon/game-toolbar/fanpage.svg" :alt="$t('Fanpage')">
                <label>{{ $t("Fanpage") }}</label>
              </a>
            </div>
          </div>
          <div class="mb-3">
            <SlickSlider :items="game.slideUrls.map((x, i) => ({
          title: 'Slider ' + i,
          imageUrl: x
        }))" :dots="true" :autoplay="true" :autoplay-speed="10000"/>
          </div>
          <div class="game-content" v-html="game.content">
          </div>
        </div>
      </div>
    </b-col>
  </div>
</template>

<script>
import gameService from "@/services/gameService";
import SlickSlider from "@/components/sliders/SlickSlider";

export default {
  name: "Detail",
  components: {SlickSlider},
  data() {
    return {
      loading: true,
      error: false,
      game: null,
      errorMessage: null
    }
  },
  created() {
    this.loadGame();
  },
  methods: {
    async loadGame() {
      this.loading = true;
      this.error = false;
      this.errorMessage = null;
      const response = await gameService.detail(this.$route.params["aliasUrl"]);
      this.loading = false;
      if (!response) {
        this.error = true;
        this.errorMessage = this.$t("Connect to server failed. Please check your internet connection");
        return;
      }

      if (response.error) {
        this.errorMessage = response.message;
        return;
      }

      this.game = response.data;
      this.$setPageTitle(this.game.seo.title);
    }
  }
}
</script>

<style lang="scss">
.page-game-detail {
  .game-header {
    .image-icon {
      height: 100px;
      width: 100px;
      object-fit: contain;
    }

    .game-content {
      h1 {
        color: #1a1a1a;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 5px;
      }

      .game-category {
        color: #999;
        font-size: 14px;
        margin-bottom: 5px;
      }
    }
  }

  .game-body {
    .game-toolbar {
      border-top: 1px solid #f3f4f4;
      border-bottom: 1px solid #f3f4f4;
      padding: 5px 0;

      .item {
        flex: 1;
        text-align: center;

        img {
          margin-bottom: 2px;
        }

        label {
          display: block;
          font-size: 13px;
          margin-bottom: 0;
        }
      }
    }

    .game-content {
      font-size: 16px;

      h3 {
        font-weight: 700;
        font-size: 18px !important;
        margin-bottom: 3px;
      }

      img, table {
        max-width: 100%;
        height: auto;
      }
    }
  }
}
</style>
